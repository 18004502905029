.categories{
    margin-top: 84px;
}

.categories .cat-images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 700px;
}


.categories .cat-images .color-back{
    height: 500px;
    position: absolute;
    z-index: -1;
    width: 35%;
    right: 119px;
    background-color: #98bb64;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 18px;
}


.categories .cat-images .overlay{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background-color: #054d3b;
    z-index: -1;
}

.categories .cat-images img{
    width: 60%;
    height: 550px;
    object-fit: cover;
    border-radius: 16px;
}




.categories .categories-text h2 {
    font-size: 17px;
    font-weight: 500;
    color: #054d3b;
    margin-bottom: 29px;
    text-align: right;
    padding-right: 83px;
}


.categories .categories-text h1 {
    font-size: 41px;
    width: 90%;
    font-weight: 700;
    color: #054d3b;
    text-align: right;
}

.categories .categories-text p{
    margin-top: 55px;
    margin-bottom: 84px;
    font-size: 15px;
    font-weight: 400;
    color: #6d6d6d;
    line-height: 28px;
    text-align: right;
    padding: 84px;
    padding-top: 0;
    padding-bottom: 0;
}

.categories .categories-text .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 31px;
    justify-content: space-around;
}

.categories .categories-text .category div{
    width: 26%;
    background-color: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
    padding: 15px;
    min-height: 157px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.categories .categories-text .category div i{
    
    font-size: 62px;
    color: #054d3b;

}


.categories .categories-text .category div h1 {
    font-size: 17px;
    margin-top: 18px;
    color: #054d3b;
    font-family: inherit;
    font-weight: 500;
    text-align: center;
}

@media(max-width:950px){
    .categories .cat-images {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 550px;
        margin-bottom: 40px;
    }

    .categories .cat-images img {
        width: 80%;
        height: 383px;
        object-fit: cover;
        border-radius: 16px;
    }

    .categories .categories-text h1 {
        font-size: 30px;
        width: 100%;
        font-weight: 700;
        color: #054d3b;
        text-align: right;
        margin: 0;
    }

    .categories .categories-text h2 {
        font-size: 17px;
        font-weight: 500;
        color: #054d3b;
        margin-bottom: 29px;
        text-align: right;
        padding-right: 0;
    }


    .categories .categories-text p {
        margin-top: 55px;
        margin-bottom: 84px;
        font-size: 15px;
        font-weight: 400;
        color: #6d6d6d;
        line-height: 28px;
        text-align: right;
        padding: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

    .categories .categories-text .category div {
        width: 45%;
        background-color: #fff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.29);
        padding: 15px;
        min-height: 157px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .categories .categories-text .category {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 31px;
        justify-content: space-around;
        margin-bottom: 39px;
    }
}