.events{
    margin-top: 80px;
    margin-bottom: 80px;
}

.events .event{
    margin-bottom: 30px;
    margin-top: 30px;
}

.events .event .event-pic{
    position: relative;
}

.events .event .event-pic img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 17px;
}



.events .event .event-pic .date{
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #054d3b;
    height: 80px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px 0 15px 0;
    color: #fff;
}


.events .event .event-pic .date span:nth-child(1){
    font-size: 24px;
    font-weight: 600;
}


.events .event .event-pic .date span:nth-child(2){
    font-size: 13px;
    font-weight: 600;
}

.events .event .info{
    display: flex;
    flex-direction: row;
    gap: 21px;
    margin-top: 20px;
    margin-bottom: 20px;
}


.events .event .info .user{
    
    font-size: 14px;

}

.events .event .info .user i{
    
    color: #054d3b;
    font-size: 20px;
}


.events .event .info .user span{
    color: #515151;
}

.events .event .info .pics-num{
    
}


.events .event .info .pics-num i{
    color: #054d3b;
    font-size: 20px;
}

.events .event .info .pics-num span{
    color: #515151;
}

.events .event h1{
    font-size: 24px;
    font-weight: 500;
    color: #000;
}


@media(max-width:950px){
    .events .event h1 {
        font-size: 24px;
        font-weight: 500;
        color: #000;
        text-align: right;
    }
}