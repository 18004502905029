

.event-page .header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 0;
    padding: 13px;
    padding-bottom: 0;
}


.event-page .header h1{
    font-size: 35px;
    color: #000;
    font-weight: 600;
}

.event-page .header h2{
    font-size: 19px;
    font-weight: 400;
    color: #747474;
    word-spacing: 17px;
    letter-spacing: 1px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 7px;
    font-weight: 500;
}

.event-page .images-slider{
    margin-top: 70px;
    margin-bottom: 81px;
}

.event-page .images-slider .swiper-slide{
    width: fit-content;
    margin-bottom: 31px;
}


.event-page .images-slider img{
    width: 350px;
    height: 250px;
    object-fit: cover;
    border-radius: 15px;
    margin: 25px;
}


.event-page .event-description p{
    text-align: right;
    font-size: 21px;
    margin-bottom: 60px;
    line-height: 35px;
    direction: rtl;
}


@media(max-width:950px){
    .event-page .images-slider img {
        width: 100%;
        height: 250px;
        object-fit: cover;
        border-radius: 15px;
        margin: 0;
    }

    .event-page .images-slider .swiper-slide {
        width: 100%;
        margin-bottom: 31px;
        margin-right: 22px;
    }
}