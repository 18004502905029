

.services .services-content .service{
    width: 460px;
    min-height: 516px;
    position: relative;
    margin: 20px;
    margin-bottom: 169px;
}

.services{
    margin-bottom: 57px;
    margin-top: 100px;
}


.services .services-content .service>img{
    width: 100%;
    border-radius: 24px;
    height: 350px;
    object-fit: cover;
}

.services .services-content .service .text{
    width: 90%;
    background-color: #054d3b;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.43);
    z-index: 12;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 83%;
    padding: 21px;
    border-radius: 26px;
    min-height: 374px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}


.services .services-content .service .text .top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.services .services-content .service .text .top .left{
    width: 63px;
    height: 63px;
    background-color: #98bb64;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 37px;
    font-size: 33px;
    margin-right: 15px;
    color: #fff;
}




.services .services-content .service .text .top .right h1 {
    font-size: 23px;
    font-weight: 700;
    color: #98bb64;
    text-align: right;
}

.services .services-content .service .text>p {
    font-size: 16px;
    margin-top: 31px;
    font-weight: 300;
    color: #fff;
    text-align: right;
    direction: rtl;
}



.services .services-content .service .text>a{
    font-size: 16px;
    margin-top: 23px;
    color: #e5ecea;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}


.services .services-content .service .text>a i{
    font-size: 24px;
    color: #98bb64;
}


.services .swiper-slide{
    width: fit-content;
}

.swiper-pagination-bullet{
    background-color: #98bb64 !important;
}


@media(max-width:950px){
    .services .services-content .service {
        width: 100%;
        min-height: 521px;
        position: relative;
        margin: 0;
        margin-bottom: 173px;
    }

    .services .swiper-slide {
        width: -moz-fit-content;
        width: 100%;
    }
}