.suppliers-page{
    margin-top: 100px;
    margin-bottom: 100px;
}

.suppliers-page .supplier{
    min-height: 250px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
    width: 100%;
    padding: 17px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.29);
    border-radius: 12px;
}

.suppliers-page .supplier img{
    width: 100%;
    height: 150px;
    object-fit: contain;
}

.suppliers-page .supplier h1{
    font-size: 24px;
    color: #000;
}