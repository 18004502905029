.products-page{
    margin-top: 60px;
    margin-bottom: 60px;
}

.products-page .product{
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    padding: 30px;
}

.products-page .product img{
    height: 300px;
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
    margin-bottom: 30px;
}

.products-page .product a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    background-color: #054d3b;
    border-radius: 51px;
    color: #fff;
}

.products-page .product h1{
    font-size: 25px;
    font-weight: 600;
    color: #000;
    margin-bottom: 16px;
}

.products-page .product h2{
    font-size: 20px;
    font-weight: 600;
}


.products-page .product h2 span{
    font-size: 16px;
    font-weight: 500;
}