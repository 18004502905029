.results-table{
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 25px;
    padding: 20px;
    overflow-x: scroll;
}

.results-table table{
    min-width: 1020px;
}


.results-table table thead th{
    font-size: 15px;
    font-weight: 600;
    margin: 5px;
    padding: 10px;
}



.results-table table tbody td{
    text-align: left;
    padding: 10px;
    margin-bottom: 12px;
    padding-bottom: 11px;
    padding-top: 11px;
    border-top: 1px solid #e3e3e3;
}

.results-table table tbody td img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}


.results-table table tbody td h2{
    font-size: 16px;
    font-weight: 700;
}
.results-table table tbody td h3{
    font-size: 16px;
    font-weight: 600;
}
.results-table table tbody td h4{
    font-size: 16px;
    background-color: #0000ff3d;
    color: blue;
    border-radius: 25px;
    text-align: center;
    padding: 3px;
}

.results-table table tbody td h4.admin{
    background-color: #ffd70052;
    color: #937c06;
}

.results-table table tbody td h5{
    font-size: 16px;
    background-color: #ff000087;
    color: #a40000;
    border-radius: 25px;
    text-align: center;
    padding: 3px;
}


.results-table table tbody td h5.active{
    background-color: #0080007d;
    color: #005800;
}

.results-table table tbody td h6{
    font-weight: 700;
}

.results-table table tbody td a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 31px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
}

.results-table table tbody td a.delete{
    background-color: #cc0303;
}

.results-table table tbody td a.activate{
    background-color: #198723;
}


.results-table table tbody td button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 31px;
    background-color: #000;
    border-radius: 5px;
    color: #fff;
    border: none;
    outline: none;
}

.results-table table tbody td button.delete{
    background-color: #cc0303;
}

.results-table table tbody td button.activate{
    background-color: #198723;
}