  @import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body{
  background-color: #fff !important;
  scroll-behavior: smooth;

}

*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'Tajawal', sans-serif;
  
}

/* font-family: 'IBM Plex Sans Arabic', sans-serif; */

a{
  text-decoration: none !important;
}

ul{
  list-style: none !important;
  margin: 0 !important;
}


.hide{
  display: none !important;
}

.facebook{
  color: #0572e6 !important;

}

.twitter{
  color: #1d9bf0 !important;
}

.instagram{
  color: #c6346b !important;
}

.tiktok{
  color: #fe2c55 !important;
}


.whatsapp{
  color: #0dc143 !important;
}



.loader {
  position: relative;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #fff;
  border-right-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}



.page-header{
  min-height: 451px;
  position: relative;
}

.page-header .overlay{
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #00000087;
}

.page-header img{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page-header .page-header-content{
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}



.page-header .page-header-content .text h1{
  font-size: 57px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 60px;
  direction: rtl;
}

.page-header .page-header-content .text .links{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.page-header .page-header-content .text .links a{
  font-size: 18px;
  color: #fff;
}

.page-header .page-header-content .text .links i{
  color: #fff;
  font-size: 19px;
}


@media(max-width:950px){
  .page-header .page-header-content .text h1 {
    font-size: 36px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 60px;
    direction: rtl;
    text-align: center;
}
}