.landing{
    min-height: 650px;
    position: relative;
}

.landing .buttons{
    position: absolute;
    top: 50%;
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 33px;
    transform: translateY(-50%);
    z-index: 2;
}

.landing .buttons button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: transparent;
    outline: none;
    border: 1px solid #717171;
    color: #717171;
}


.landing .landing-content{
    min-height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing .landing-content .overlay{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000069;
}

.landing .landing-content img{
    width: 100%;
    height: 650px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}



.landing .landing-content h2{
    position: relative;
    font-size: 21px;
    color: #FFF;
    z-index: 2;
}


.landing .landing-content h1{
    position: relative;
    color: #fff;
    font-size: 77px;
    text-transform: uppercase;
    font-weight: 600;
    z-index: 2;
}

.landing .landing-content a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 15px;
    background-color: #054d3b;
    position: relative;
    z-index: 2;
    width: 233px;
    border-radius: 46px;
    height: 52px;
    margin-top: 60px;
    color: #fff;
    transition: 0.3s;
}

.landing .landing-content a i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #98bb64;
    border-radius: 50%;
    font-size: 19px;
}

.landing .landing-content .facts{
    margin-top: 171px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    position: relative;
    z-index: 2;
    width: 85%;
    padding-top: 20px;
    border-top: 1px solid #ffffff14;
}

.landing .landing-content .facts .fact{
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 33%;
    align-items: center;
    justify-content: center;
}

.landing .landing-content .facts .fact i{
    font-size: 76px;
    color: #91bd54;
}

.landing .landing-content .facts .fact h2{
    font-size: 23px;
    width: 57%;
    font-weight: 700;
    text-align: right;
}


@media(max-width:950px){
    .landing .landing-content h1 {
        position: relative;
        color: #fff;
        font-size: 41px;
        text-transform: uppercase;
        font-weight: 600;
        z-index: 2;
        text-align: right;
    }

    .landing .buttons {
        display: none;
    }

    .landing .landing-content .facts {
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
        position: relative;
        z-index: 2;
        width: 85%;
        padding-top: 20px;
        border-top: 1px solid #ffffff14;
    }

    .landing .landing-content .facts .fact {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 33%;
        align-items: center;
        justify-content: center;
    }

    .landing .landing-content .facts .fact i {
        font-size: 50px;
        color: #91bd54;
    }
    .landing .landing-content .facts .fact h2 {
        font-size: 19px;
        width: 100%;
        font-weight: 300;
        text-align: right;
        text-align: center;
    }
}