footer{

    background-image: url('../images/ad_1.webp');
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    min-height: 350px;
    width: 100%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

}

footer .overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #054d3beb;
}

footer .footer-content{
    position: relative;
    z-index: 1;
}


footer .footer-content .logo img{
    width: 150px;
    height: 150px;
    object-fit: contain;
    background-color: #fff;
    border-radius: 10px;
    padding: 7px;
}

footer .footer-content p{
    font-size: 19px;
    color: #fff;
    font-weight: 300;
    margin-top: 38px;
}

footer .footer-content h1{
    font-size: 29px;
    color: #fff;
}


footer .footer-content ul{
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-top: 55px !important;
    color: #Fff;
}


footer .footer-content ul li a{
    color: #fff;
    font-size: 17px;
}


footer .footer-content .latest-events .event{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 33px;
}



footer .footer-content .latest-events .event img{
    width: 106px;
    height: 69px;
    object-fit: cover;
    border-radius: 9px;
}

footer .footer-content .latest-events .event .text{
    margin-left: 21px;
    width: 78%;
}

footer .footer-content .latest-events .event .text h2{
    font-size: 16px;
    color: #98bb64;
}

footer .footer-content .latest-events .event .text h1{
    font-size: 18px;
}

footer .footer-content .links{
    display: flex;
    flex-direction: column;
    margin-top: 31px;
    gap: 26px;
    color: #ffff;
}


footer .footer-content .links a{
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}


footer .footer-content .links a i{
    color: #98bb64;
}

@media(max-width:950px){
    
footer {
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    min-height: 350px;
    width: 100%;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    direction: rtl;
}

footer .footer-content .latest-events .event img {
    width: 106px;
    height: 69px;
    object-fit: cover;
    border-radius: 9px;
    margin-left: 19px;
}
}