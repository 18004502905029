.posts{

}

.posts .post{
    margin-bottom: 80px;
    margin-top: 80px;
}

.posts .post .info{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.posts .post .info div{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 20px;
}

.posts .post .info div img{
    width: 90px;
    height: 90px;
    object-fit: contain;
    border-radius: 50%;
}

.posts .post .info div h1{
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.posts .post .info h2{
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #7e7e7e;
}

.posts .post>p{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: right;
    flex-wrap: wrap;
    justify-content: flex-start;
    direction: rtl;
}


.posts .post>p button{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: #054d3b;
}

.posts .post>img{
    width: 100%;
    height: 450px;
    border-radius: 15px;
    object-fit: cover;
}