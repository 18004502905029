.ad{
    background-image: url('../images/ad_1.webp');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 350px;
    width: 100%;
    margin-bottom: 79px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

}

.ad .overlay{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000061;
}


.ad .ad-content .text{
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    padding: 18px;
    border: 1px solid #ffffff24;
    border-radius: 16px;
}

.ad .ad-content .text i{
    font-size: 60px;
    color: #fff;
}

.ad .ad-content .text h2{
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    margin-top: 31px;
    margin-bottom: 23px;
}

.ad .ad-content .text h1{
    font-size: 46px;
    color: #fff;
    font-weight: 700;
    width: 69%;
    text-align: center;
    line-height: 65px;
}


.ad .ad-content .text a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 15px;
    background-color: #054d3b;
    position: relative;
    z-index: 2;
    width: 233px;
    border-radius: 46px;
    height: 52px;
    margin-top: 60px;
    color: #fff;
    transition: 0.3s;
}

.ad .ad-content .text a i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #98bb64;
    border-radius: 50%;
    font-size: 19px;
}


@media(max-width:950px){
    .ad .ad-content .text h1 {
        font-size: 25px;
        color: #fff;
        font-weight: 700;
        width: 100%;
        text-align: center;
        line-height: 40px;
    }
    
}