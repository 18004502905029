.section-header .section-header-content{
    margin-top: 80px;
    margin-bottom: 60px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.section-header .section-header-content .sec-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 11px;
}

.section-header .swiper-slide{
    width: fit-content;
}

.section-header .section-header-content .sec-header i{
    font-size: 23px;
    color: #054d3b;
}

.section-header .section-header-content .sec-header h2{
    font-size: 20px;
    color: #054d3b;
}

.section-header .section-header-content h1 {
    width: 60%;
    text-align: center;
    line-height: 51px;
    color: #054d3b;
    font-weight: 500;
    font-size: 66px;
    font-weight: 700;
}

@media(max-width:950px){
    .section-header .section-header-content h1 {
        width: 100%;
        text-align: center;
        line-height: 51px;
        color: #054d3b;
        font-weight: 500;
        font-size: 46px;
        font-weight: 700;
    }
}