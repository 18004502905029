.numbers{
    margin-top: 90px;
    margin-bottom: 90px;
}

.numbers .number{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 20px;
}


.numbers .number i{
    font-size: 57px;
    color: #054d3b;
}

.numbers .number h3{
    font-size: 44px;
    font-weight: 700;
    color: #054d3b;
}

.numbers .number h4 {
    font-size: 23px;
    font-weight: 700;
}
