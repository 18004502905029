.about{
    margin-top: 100px;
    margin-bottom: 100px;
}

.about .about-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 28px;
    text-align: right;
}

.about .about-text h2{
    font-size: 17px;
    font-weight: 400;
    color: #054d3b;
}

.about .about-text h1 {
    font-size: 43px;
    font-weight: 700;
    color: #054d3b;
}

.about .about-text i{
    font-size: 66px;
    color: #054d3b;
}

.about .about-text h3{
    font-size: 20px;
    color: #054d3b;
}


.about .about-text p{
    font-size: 15px;
    line-height: 38px;
    width: 87%;
    color: #838383;
}

.about .about-text ul{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 27px;
}

.about .about-text ul li{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-align: right;
}


.about .about-text ul li i{
    font-size: 21px;
}


.about .about-text a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 15px;
    background-color: #054d3b;
    position: relative;
    z-index: 2;
    width: 233px;
    border-radius: 46px;
    height: 52px;
    margin-top: 60px;
    color: #fff;
    transition: 0.3s;
}


.about .about-text a i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #98bb64;
    border-radius: 50%;
    font-size: 19px;
    color: #fff;
}

.about .about-images{
    position: relative;
    min-height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
}

.about .about-images .small-img{
    width: 100%;
    position: absolute;
    width: 50%;
    height: 250px;
    border-radius: 16px;
    object-fit: cover;
    top: 33%;
    left: 62px;
}

.about .about-images .large-img{
    width: 57%;
    height: 552px;
    object-fit: cover;
    margin-right: 47px;
    border-radius: 16px;
}

.about .about-images .back-layer{
    height: 500px;
    position: absolute;
    z-index: -1;
    width: 35%;
    right: 32px;
    background-color: #98bb64;
    top: 32%;
    transform: translateY(-50%);
    border-radius: 18px;
}

.about .about-images .seads {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0;
    object-fit: contain;
    opacity: 0.2;
    z-index: -1;
}

@media(max-width:950px){
    .about .about-text h1 {
        font-size: 23px;
        font-weight: 700;
        color: #054d3b;
    }

    .about .about-images .large-img {
        width: 100%;
        height: 439px;
        object-fit: cover;
        margin-right: 0;
        border-radius: 16px;
    }

    .about .about-images .back-layer {
        height: 500px;
        position: absolute;
        z-index: -1;
        width: 75%;
        right: 32px;
        background-color: #98bb64;
        top: 32%;
        transform: translateY(-50%);
        border-radius: 18px;
    }

    .about .about-images {
        position: relative;
        min-height: 550px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 100%;
        margin-top: 80px;
    }

    .about .about-images .back-layer {
        height: 500px;
        position: absolute;
        z-index: -1;
        width: 75%;
        right: 32px;
        background-color: #98bb64;
        top: 32%;
        transform: translateY(-50%);
        border-radius: 18px;
        display: none;
    }

    .about .about-images .small-img {
        width: 100%;
        position: absolute;
        width: 50%;
        height: 250px;
        border-radius: 16px;
        object-fit: cover;
        top: 33%;
        left: 62px;
        display: none;
    }
}