.contact{
    margin-bottom: 50px;
    margin-top: 50px;
}
.contact .contact-content-text {
    text-align: right;
    direction: rtl;
}
.contact .contact-content-text h2{
    font-size: 20px;
    color: #054d3b;
    font-weight: 300;
}

.contact .contact-content-text h1{
    font-size: 50px;
    margin-top: 31px;
    margin-bottom: 30px;
    font-weight: 700;
    color: #054d3b;
}

.contact .contact-content-text p{
    font-size: 16px;
    margin-top: 45px;
    margin-bottom: 45px;
    font-weight: 400;
    color: #5d5d5d;
}

.contact .contact-content-text .contact-info{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.contact .contact-content-text .contact-info .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 22px;
}


.contact .contact-content-text .contact-info .info i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #054d3b;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
}


.contact .contact-content-text .contact-info .info .text h2{
    font-size: 18px;
    font-weight: 400;
    color: #8b8b8b;
}

.contact .contact-content-text .contact-info .info .text a{
    font-size: 17px;
    color: #000;
    font-weight: 500;
}


.contact .contact-form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.contact .contact-form form{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #eee;
    min-height: 550px;
    gap: 40px;
}

.contact .contact-form form .inputs{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
}

.contact .contact-form form .inputs .input{
    width: 100%;
}



.contact .contact-form form .inputs .input input{
    width: 100%;
    border: none;
    outline: none;
    height: 45px;
    padding: 15px;
}


.contact .contact-form form .inputs .input textarea{
    width: 100%;
    border: none;
    outline: none;
    height: 200px;
    padding: 15px;
}


.contact .contact-form form button{
    margin-top: 52px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px 5px 15px;
    background-color: #054d3b;
    position: relative;
    z-index: 2;
    width: 233px;
    border-radius: 46px;
    height: 52px;
    margin-top: 60px;
    color: #fff;
    transition: 0.3s;
    border: none;
    outline: none;
}

.contact .contact-form form button i{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    background-color: #98bb64;
    border-radius: 50%;
    font-size: 19px;
}

@media(max-width:950px){
    .contact .contact-content-text .contact-info {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 34px;
    }

    .contact .contact-form form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        background-color: #eee;
        min-height: 550px;
        gap: 40px;
    }

    .contact .contact-form form .inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 17px;
        text-align: right;
        direction: rtl;
    }
}