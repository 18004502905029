.product-page{

}

.product-page .header{
    text-align: right;
    margin-top: 60px;
    margin-bottom: 60px;
}

.product-page .header h1{
    font-size: 23px;
    font-weight: 600;
    color: #000;
    direction: rtl;
    margin-bottom: 19px;
}

.product-page .header h1 span{
    color: #054d3b;
}

.product-page .header h2{
    font-size: 18px;
    direction: rtl;
    color: #000;
}


.product-page .header h3{
    font-size: 18px;
    direction: rtl;
    color: #000;
}


.product-page .product-img{
    align-items: flex-start;
    border-bottom: 1px solid #00000014;
    border-top: 1px solid #00000014;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-content: flex-end;
    padding-bottom: 40px;
    padding-top: 40px;
    gap: 50px;
}



.product-page .product-img .catalog button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 40px;
    border-radius: 28px;
    border: none;
    outline: none;
    background-color: #054d3b;
    color: #fff;
}


.product-page .product-img .img{
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 42px;
    justify-content: center;
    overflow: hidden;
    width: 390px;
    width: 30%;
}


.product-page .product-img .img img {
    border-radius: 16px;
    width: 100%;
    height: 350px;
    object-fit: cover;
    -webkit-user-select: none;
    user-select: none;
    margin-bottom: 47px;
}

.product-page .product-img .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 19px;
    padding-bottom: 0;
}

.product-page .product-img .buttons i{
    font-size: 31px;
    color: #054d3b;
    cursor: pointer;
}

.product-page .product-img .swiper-slide{
    width: 100% !important;
}

.product-page .product-img .img a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 40px;
    border-radius: 28px;
    border: none;
    outline: none;
    background-color: #054d3b;
    color: #fff;
}


.product-page .description{
    text-align: right;
    direction: rtl;
    margin-bottom: 90px;
    width: 50%;
}

.product-page .description h1{
    font-size: 44px;
    font-weight: 600;
    color: #054d3b;
}

.product-page .description p{

    width: 66%;
    font-size: 22px;
}

.product-page .description p {
    width: 66%;
    font-size: 18px;
    margin-right: 30px;
    margin-left: 30px;
}
.product-page .specifications{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-content: center;
    margin-bottom: 65px;
}

.product-page .specifications h1{
    text-align: center;
    color: #000;
    font-size: 22px;
    margin-bottom: 36px;
}

.product-page .specifications div{
    width: 65%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 17px;
    margin-bottom: 22px;
    background-color: #eee;
    padding: 6px 32px 6px 32px;
    border-radius: 29px;
}


.product-page .specifications div span{
    font-size: 19px;
}



@media(max-width:950px){
    .product-page .product-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        border-top: 1px solid #00000014;
        border-bottom: 1px solid #00000014;
        gap: 28px;
    }


    .product-page .description {
        text-align: right;
        direction: rtl;
        margin-top: 26px;
        margin-bottom: 90px;
    }

    .product-page .description p {
        width: 100%;
        font-size: 22px;
    }

    .product-page .specifications div {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        gap: 17px;
        margin-bottom: 22px;
        background-color: #eee;
        padding: 6px 32px 6px 32px;
        border-radius: 29px;
    }

    .product-page .description {
        text-align: right;
        direction: rtl;
        margin-top: 26px;
        margin-bottom: 90px;
        width: 100%;
        padding: 0;
        margin: 0;
    }

    .product-page .description p {
        width: 100%;
        font-size: 22px;
        padding: 5px;
        margin: 0;
    }

    .product-page .product-img .img {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 42px;
        justify-content: center;
        overflow: hidden;
        width: 390px;
        width: 100%;
    }

    .product-page .product-img {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-around;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        border-top: 1px solid #00000014;
        border-bottom: 1px solid #00000014;
        gap: 28px;
    }
}