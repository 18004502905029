
nav .navbar-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

nav .logo{
    width: 20%;
}

nav .logo img{
    width: 150px;
    height: 150px;
    object-fit: contain;
}

nav .nav-content{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

nav .nav-content .upper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 20px 10px 20px;
    background-color: #054d3b;
    border-radius: 30px;
}

nav .nav-content .upper .address{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
}


nav .nav-content .upper .address a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
}


nav .nav-content .upper .address a i{
    font-size: 14px;
    color: #8fbd4f;
}


nav .nav-content .upper .links{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 19px;
    color: #fff;
}

nav .nav-content .upper .links a{
    color: #fff;
}

nav .nav-content .lower{
    width: 100%;
}


nav .nav-content .lower .nav-elements ul {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-around;
    margin-top: 23px !important;
}

nav .nav-content .lower .nav-elements li a{
    font-size: 17px;
    transition: 0.3s;
    color: #9c9c9c;
}

nav .nav-content .lower .nav-elements li a.active{
    color: #000;
}

nav .call-us{
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

nav .call-us a{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    color: #054d3b;
    border: 1px solid;
    font-size: 22px;
    transition: 0.3s;
}





#open-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
    color: #000;
}

#close-nav{
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 31px;
    display: none;
}


@media(max-width:950px){

    
    #open-nav{
        display: block;
    }

    #close-nav{
        display: block;
    }

    nav .nav-content .nav-elements {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 60px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        height: 100%;
        width: 100%;
        justify-content: center;
        z-index: 15015;
    }

    nav .nav-content .nav-elements ul {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        gap: 40px;
        padding: 0;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

    }

    
    nav .nav-content .nav-elements{
        display: none;
    }

    nav .nav-content .nav-elements ul li a {
        color: #000;
        font-size: 17px;
        font-weight: 500;
    }

    nav .logo img {
        width: 108px;
        height: 138px;
        object-fit: contain;
    }

    nav .call-us {
        width: 20%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        display: none;
    }


    nav .nav-content .lower {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
    }

    nav .nav-content .upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 20px 10px 20px;
        background-color: #054d3b;
        border-radius: 30px;
    }

    nav .nav-content .upper .address a {
        display: none;
    }

}

